const COUNTIES = [
  {
    siteId: 1,
    name: 'Bedfordshire',
    url: 'https://herts.muddystilettos.co.uk'
  },
  {
    siteId: 2,
    name: 'Berkshire',
    url: 'https://berkshire.muddystilettos.co.uk'
  },
  {
    siteId: 3,
    name: 'Buckinghamshire',
    url: 'https://bucksoxon.muddystilettos.co.uk'
  },
  {
    siteId: 4,
    name: 'Cambridgeshire',
    url: 'https://suffolkcambs.muddystilettos.co.uk'
  },
  {
    siteId: 5,
    name: 'Cornwall',
    url: 'https://cornwall.muddystilettos.co.uk'
  },
  {
    siteId: 6,
    name: 'Derbyshire',
    url: 'https://nottsderbyshire.muddystilettos.co.uk'
  },
  {
    siteId: 7,
    name: 'Devon',
    url: 'https://devon.muddystilettos.co.uk'
  },
  {
    siteId: 8,
    name: 'Dorset',
    url: 'https://dorsetsomerset.muddystilettos.co.uk'
  },
  {
    siteId: 9,
    name: 'Essex',
    url: 'https://essex.muddystilettos.co.uk'
  },
  {
    siteId: 10,
    name: 'Gloucestershire',
    url: 'https://glosworcs.muddystilettos.co.uk'
  },
  {
    siteId: 11,
    name: 'Hampshire',
    url: 'https://hants.muddystilettos.co.uk'
  },
  {
    siteId: 11,
    name: 'Hertfordshire',
    url: 'https://herts.muddystilettos.co.uk'
  },
  {
    siteId: 12,
    name: 'Isle of Wight',
    url: 'https://hants.muddystilettos.co.uk'
  },
  {
    siteId: 13,
    name: 'Kent',
    url: 'https://kent.muddystilettos.co.uk'
  },
  {
    siteId: 14,
    name: 'Leicestershire',
    url: 'https://northantsleicsrutland.muddystilettos.co.uk'
  },
  {
    siteId: 28,
    name: 'London',
    url: 'https://london.muddystilettos.co.uk'
  },
  {
    siteId: 15,
    name: 'Norfolk',
    url: 'https://norfolk.muddystilettos.co.uk'
  },
  {
    siteId: 16,
    name: 'Northamptonshire',
    url: 'https://northantsleicsrutland.muddystilettos.co.uk'
  },
  {
    siteId: 17,
    name: 'Nottinghamshire',
    url: 'https://nottsderbyshire.muddystilettos.co.uk'
  },
  {
    siteId: 18,
    name: 'Oxfordshire',
    url: 'https://bucksoxon.muddystilettos.co.uk'
  },
  {
    siteId: 19,
    name: 'Rutland',
    url: 'https://northantsleicsrutland.muddystilettos.co.uk'
  },
  {
    siteId: 20,
    name: 'Somerset',
    url: 'https://dorsetsomerset.muddystilettos.co.uk'
  },
  {
    siteId: 21,
    name: 'Suffolk',
    url: 'https://suffolkcambs.muddystilettos.co.uk'
  },
  {
    siteId: 22,
    name: 'Surrey',
    url: 'https://surrey.muddystilettos.co.uk'
  },
  {
    siteId: 23,
    name: 'Sussex',
    url: 'https://sussex.muddystilettos.co.uk'
  },
  {
    siteId: 24,
    name: 'Warwickshire',
    url: 'https://warks.muddystilettos.co.uk'
  },
  {
    siteId: 25,
    name: 'West Midlands',
    url: 'https://warks.muddystilettos.co.uk'
  },
  {
    siteId: 26,
    name: 'Wiltshire',
    url: 'https://wilts.muddystilettos.co.uk'
  },
  {
    siteId: 27,
    name: 'Worcestershire',
    url: 'https://glosworcs.muddystilettos.co.uk'
  }
  // {
  //   siteId: 26,
  //   name: 'Northants, Leics & Rutland',
  //   url: 'https://northantsleicsrutland.muddystilettos.co.uk'
  // }
]

const MULTISITE_COUNTIES = [
  {
    id: 1,
    title: 'Muddy Stilettos',
    shortName: 'The Rest of the UK',
    url: 'https://muddystilettos.co.uk',
    siteVerification: 'uv46YhiW9Um4kcnWKHdq9lWTsZgKKL73uFJKuJ4bfYM'
  },
  {
    id: 2,
    title: 'Hertfordshire & Bedfordshire',
    shortName: 'Herts & Beds',
    url: 'https://herts.muddystilettos.co.uk',
    siteVerification: 'fv8TLChu_TzF5qWEL4Ubpkz6oa-6QrcbNuh-lZL0jYc'
  },
  {
    id: 3,
    title: 'Buckinghamshire & Oxfordshire',
    shortName: 'Bucks & Oxon',
    url: 'https://bucksoxon.muddystilettos.co.uk',
    siteVerification: ''
  },
  {
    id: 5,
    title: 'Berkshire',
    shortName: 'Berkshire',
    url: 'https://berkshire.muddystilettos.co.uk',
    siteVerification: '2hQJ-x54gPKdXnFVGwes1yHGJgfHPmCul1c2Ur2FdfQ'
  },
  {
    id: 6,
    title: 'Northamptonshire',
    shortName: 'Northamptonshire',
    url: 'https://northants.muddystilettos.co.uk',
    siteVerification: ''
  },
  {
    id: 7,
    title: 'Surrey',
    shortName: 'Surrey',
    url: 'https://surrey.muddystilettos.co.uk',
    siteVerification: '4eCEw2lwQKQJmGXxStmY5W5W3X0sZ1O9mV2DlIZKoJg'
  },
  {
    id: 8,
    title: 'Somerset & Dorset',
    shortName: 'Somerset & Dorset',
    url: 'https://dorsetsomerset.muddystilettos.co.uk',
    siteVerification: 'PNZQu6MzBXQhcWvJdOj6-9jFAHUa580846O2DKBp3Bc'
  },
  {
    id: 9,
    title: 'Cornwall',
    shortName: 'Cornwall',
    url: 'https://cornwall.muddystilettos.co.uk',
    siteVerification: '-M2zu2wyghpUS5c_7zMvKzqELHCfOGNjB5aaQGO1Iag'
  },
  {
    id: 10,
    title: 'Kent',
    shortName: 'Kent',
    url: 'https://kent.muddystilettos.co.uk',
    siteVerification: 'Aspbh2Bp5TXzXAwh4yUJpBGSdiyffenfsjZHj0zzbtE'
  },
  {
    id: 11,
    title: 'Sussex',
    shortName: 'Sussex',
    url: 'https://sussex.muddystilettos.co.uk',
    siteVerification: '3TsQL-b-Bxo12c7y3sYIcO19WAMLm_945XeyGZK2Ylo'
  },
  {
    id: 12,
    title: 'Norfolk',
    shortName: 'Norfolk',
    url: 'https://norfolk.muddystilettos.co.uk',
    siteVerification: 'X1nW0k4ZhLundaz2vOmKCe-mQpeMlpRkndQfRqgRxm4'
  },
  {
    id: 13,
    title: 'Gloucestershire & Worcestershire',
    shortName: 'Glos & Worcs',
    url: 'https://glosworcs.muddystilettos.co.uk',
    siteVerification: 'vWddVWe5Yeg9ceVWcb4OKMRCgRth2fQcny9RSPCgy_Y'
  },
  {
    id: 14,
    title: 'Devon',
    shortName: 'Devon',
    url: 'https://devon.muddystilettos.co.uk',
    siteVerification: 'PQQph7NAqx8YxbLMX61ZhKnTgQRJxltRlWdQtRuChJ8'
  },
  {
    id: 15,
    title: 'Suffolk & Cambridgeshire',
    shortName: 'Suffolk & Cambridgeshire',
    url: 'https://suffolkcambs.muddystilettos.co.uk',
    siteVerification: 'HMbKi9NLfwQjQsCc23EFb8oXEpLlaUClgMHgSkTSNl0'
  },
  {
    id: 16,
    title: 'Leicestershire & Rutland',
    shortName: 'Leics & Rutland',
    url: 'https://leics.muddystilettos.co.uk',
    siteVerification: ''
  },
  {
    id: 17,
    title: 'Hampshire & Isle of Wight',
    shortName: 'Hampshire & IoW',
    url: 'https://hants.muddystilettos.co.uk',
    siteVerification: 'FMBZgpeIO4SpnmUfiKO8FPtL1_kL3d-agr0p-w4E-6Y'
  },
  {
    id: 18,
    title: 'Warwickshire & West Midlands',
    shortName: 'Warks & West Mids',
    url: 'https://warks.muddystilettos.co.uk',
    siteVerification: '6NK9RqsT8vwC8LlKlCwTNDSAqQBELfkAzc0goN6ihnc'
  },
  {
    id: 19,
    title: 'Wiltshire',
    shortName: 'Wiltshire',
    url: 'https://wilts.muddystilettos.co.uk',
    siteVerification: '3VPQZZAzBNW-7Athzi4i7qDocLhsM1eAN9OP8_my4Iw'
  },
  {
    id: 22,
    title: 'Essex',
    shortName: 'Essex',
    url: 'https://essex.muddystilettos.co.uk',
    siteVerification: 'Ic0mzCj1l51f779531s0_NIXFDraRmioRccjGvuwb3A'
  },
  {
    id: 23,
    title: 'Nottinghamshire & Derbyshire',
    shortName: 'Notts & Derbyshire',
    url: 'https://nottsderbyshire.muddystilettos.co.uk',
    siteVerification: 'Wc3Kdy7A4hHOhr08d3vO7cg8Kpdx6hm43sDccp8Z8Cw'
  },
  {
    id: 24,
    title: 'London',
    shortName: 'London',
    url: 'https://london.muddystilettos.co.uk',
    siteVerification: '_V54cBBXK_Nz3YzcZxbKc4Dyqj-WwSMVUyb1mL51OIs'
  },
  {
    id: 26,
    title: 'Northants, Leics & Rutland',
    shortName: 'Northants, Leics & Rutland',
    url: 'https://northantsleicsrutland.muddystilettos.co.uk',
    siteVerification: 'ZfbjJ15hi3zWrwXoRZou3WuY4weNPmEeVfjGvUnUP9w'
  },
  {
    id: 109,
    title: 'Yorkshire',
    shortName: 'Yorkshire',
    url: 'https://yorkshire.muddystilettos.co.uk'
  },
  {
    id: 110,
    title: 'Cheshire',
    shortName: 'Cheshire',
    url: 'https://cheshire.muddystilettos.co.uk'
  }
]

export { COUNTIES, MULTISITE_COUNTIES }
